import React, { Component } from "react";
import { Card, CardBody, CardGroup, Col, Container, Row } from "reactstrap";
import { Form, Icon, Input, Button, message, Checkbox, Modal, Divider, Avatar, Col as AntCol, Row as AntRow } from "antd";
import "../../../../node_modules/antd/dist/antd.css";
import UserService from "../../../services/UserServices";
import { Link } from "react-router-dom";
import { Upload } from "antd/lib/upload";
import Api from "../../../services/Api";
import "../../../scss/_custom.scss";
import logo from '../../../assets/App-Icon.png'

import styled from "styled-components";

const CustomModal = styled(Modal)`
  .ant-modal-content {
    padding: 40px;
    border-top: 3px solid #66b9f4;
  }

  .ant-modal-header {
    padding: 0;
    border: none;
  }

  .ant-modal-body {
    padding: 20px 0 20px 0;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .ant-modal-footer {
    padding: 0;
    border: none;
    // text-align: center;

    button {
      width: 136px;
      height: 39.7px;
    }

    button:last-child {
      background: #66b9f4;
      border-color: #66b9f4;
    }
  }
`;

const FormItem = Form.Item;

class Login extends Component {
  state = {
    visible: false,
    teacherType: false,
    licensed: false
  }
  handleSubmitLogin = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        UserService.login(values.userName, values.password)
          .then(response => {
            if (response.success) {
              message.success(response.message);

              this.props.history.push("/");

            } else {
              message.error(response.message);
            }
          })
          .catch(error => {
            message.error(error.message);
          });
      }
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  }

  acceptLicense = e => {
    this.setState({
      licensed: e.target.checked
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      // if (!err) {\
      // values.username = this.state.username;
      // values.email = this.state.email;
      // values.first_name = this.state.first_name;
      // values.last_name = this.state.last_name;
      // values.fullname = this.state.fullname;
      // values.gender = this.state.gender;
      // values.picture = this.state.picture;

      // values.emailVerified = true;
      delete values.password;
      values.password = values.passwordT;
      delete values.passwordT;
      delete values.userName;
      delete values.remember;
      Api.create("users", values, null)
        .then(response => {
          this.roleMappling(response).bind(this)
        })
        .catch(error => {
          this.props.history.push("/console");
          message.error("Errore durante la creazione dell'insegnante. La mail inserita non è nel formato corretto o è gia stata utilizzata.");
        });
      // }

    });
  };

  roleMappling(values) {

    Api.findRelated("users", "sendEmail1", values.data.id)
      .then(response => {
        message.success("email inviata!");
      })
      .catch(error => {
        message.error("An error occurred while sending Email !");
      });

    if (this.state.teacherType) {
      const filter1 = `filter={"where":{"name" : "Teacher"}}`;
      var value = {};
      value.principalId = values.data.id;

      Api.find("roles", null, filter1)
        .then(response => {
          value.roleId = response.data[0].id;
          value.principalType = "USER";

          Api.create("roleMappings", value, null)
            .then(response => {
              message.success("Benvenuto, attendi che l'Amministrazione autorizzi la tua iscrizione, grazie.");
              this.props.history.push("/registrationTeacher");
            })
            .catch(error => {
              message.error("Error mapping user to role.");
            });



        })
        .catch(error => {
          message.error("Error while getting role id.");
        });

    } else {
      const filter1 = `filter={"where":{"name" : "Student"}}`;
      var value = {};
      value.principalId = values.data.id;

      Api.find("roles", null, filter1)
        .then(response => {
          value.roleId = response.data[0].id;
          value.principalType = "USER";

          Api.create("roleMappings", value, null)
            .then(response => {
              message.success("Insegnante creato con successo.");
              this.props.history.push("/registrationTeacher");
            })
            .catch(error => {
              message.error("Error mapping user to role.");
            });



        })
        .catch(error => {
          message.error("Error while getting role id.");
        });

    }

    // Api.find("roles",null,filter1)
    //   .then(response => {
    //     value.roleId = response.data.id;
    //
    //     const filter = `filter={"where":{"email" : "${values.email}"}}`;
    //     Api.create("users", null, filter)
    //       .then(response => {
    //
    //         value.principalType = "USER";
    //
    //         Api.create("users",null,filter)
    //           .then(response => {
    //             value.principalId = response.data[0].id;
    //           })
    //       .catch(error => {
    //         message.error("Error while Creating the Events");
    //       });
    //   })



  }

  render() {

    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    };

    function onChange(e) {
      this.setState({
        teacherType: e.target.checked,
      })
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>

                <Card className="p-4" >
                  <CardBody className="cardBody">
                    <Avatar size={60} className="logo" style={{
                      color: '#f56a00', alignSelf: 'center',
                    }} src={logo} />
                    <br />

                    <Form onSubmit={this.handleSubmitLogin} className="login-form">
                      <FormItem>
                        {getFieldDecorator("userName", {
                          rules: [
                            {
                              required: true,
                              message: "Please input your username!"
                            }
                          ]
                        })(
                          <Input
                            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                            placeholder="Username"
                          />
                        )}
                      </FormItem>
                      <FormItem>
                        {getFieldDecorator("password", {
                          rules: [
                            {
                              required: true,
                              message: "Please input your Password!"
                            }
                          ]
                        })(
                          <Input
                            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                            type="password"
                            placeholder="Password"
                          />
                        )}
                      </FormItem>
                      <FormItem>
                        {getFieldDecorator("remember", {
                          valuePropName: "checked",
                          initialValue: true
                        })(<Checkbox>Remember me</Checkbox>)}
                        <Link
                            color="link"
                            className="px-0"
                            to="/forgotPassword" 
                          >
                            Forgot password
                          </Link>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                          Log in
                        </Button>
                        <a onClick={this.showModal}>
                          <span style={{ fontSize: 11, color: "#3FA9FF" }}>Nuova Registrazione</span>
                        </a>
                      </FormItem>
                    </Form>
                  </CardBody>
                </Card>

                <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: '44%' }}>
                  <CardBody className="text-center">
                    <div>
                      <p><b>Benvenuti in 3elleorienta Dashboard</b></p>
                      <p>Questa area è riservata ai docenti che intendono sperimentare
                        le unità di didattica Orientativa previste nel progetto 3elleorienta Life
                        Long Learning. I docenti ORIENTATORI, autorizzati dall'Amministratore
                        del sito, saranno abilitati a funzioni speciali. Buon Lavoro.</p>
                      {/*<Link to="/DownloadFile">*/}
                      {/*<Button color="primary" className="mt-3" active tabIndex={-1}>Register Now!</Button>*/}
                      {/*</Link>*/}
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>

        <CustomModal
          // title="Modulo di Registrazione per l'Insegnante"
          visible={this.state.visible}
          onOk={this.handleSubmit.bind(this)}
          onCancel={this.handleCancel}
          width={1100}
          okText="Registrati"
          cancelText="Cancella"
          footer={[

            <Button key="Cancella" onClick={this.handleCancel}>
              Cancella
            </Button>,
            <Button disabled={!this.state.licensed} style={{ backgroundColor: this.state.licensed ? "" : "grey" }} type="primary" key="Registrati" onClick={this.handleSubmit.bind(this)}>
              Registrati
           </Button>,
          ]}

        >
          <AntRow gutter={1}>
            <AntCol span={12}>

              <div className="align-justify animated fadeIn">
                <h5 style={{ marginTop: -30 }} >Modulo di Registrazione per l'Insegnante </h5>
                <br />
                <Form onSubmit={this.handleSubmit.bind(this)}>
                  <FormItem {...formItemLayout} label="Utente">
                    {getFieldDecorator("username", {
                      rules: [{ required: false, message: "Si Prega di Inserire Username" }]
                    })(<Input prefix={<Icon type="edit" style={{ color: "rgba(0,0,0,.25)" }} />} autosize={{ minRows: 3 }} />)}
                  </FormItem>

                  <FormItem {...formItemLayout} label="E-mail">
                    {getFieldDecorator("email", {
                      rules: [{ required: false, message: "Si Prega di Inserire E-mail" }]
                    })(<Input prefix={<Icon type="edit" style={{ color: "rgba(0,0,0,.25)" }} />} autosize={{ minRows: 3 }} />)}
                  </FormItem>

                  <FormItem {...formItemLayout} label="Nome">
                    {getFieldDecorator("first_name", {
                      rules: [{ required: false, message: "Si prega di Inserire il Nome!" }]
                    })(<Input prefix={<Icon type="edit" style={{ color: "rgba(0,0,0,.25)" }} />} />)}
                  </FormItem>
                  <FormItem {...formItemLayout} label="Cognome">
                    {getFieldDecorator("last_name", {
                      rules: [{ required: false, message: "Si prega di Inserire il Cognome" }]
                    })(<Input prefix={<Icon type="edit" style={{ color: "rgba(0,0,0,.25)" }} />} autosize={{ minRows: 3 }} />)}
                  </FormItem>

                  <FormItem {...formItemLayout} label="Password">
                    {getFieldDecorator("passwordT", {
                      rules: [{ required: false, message: "Si prega di Inserire la Password" }]
                    })(<Input prefix={<Icon type="edit" style={{ color: "rgba(0,0,0,.25)" }} />} autocomplete="false" type="password" autosize={{ minRows: 3 }} />)}
                  </FormItem>

                  {/*<FormItem {...formItemLayout} label="Foto">*/}
                  {/*<div className="dropbox">*/}
                  {/*{getFieldDecorator("dragger")(*/}
                  {/*<Upload.Dragger*/}
                  {/*beforeUpload={this.beforeUpload}*/}
                  {/*onRemove={this.onRemove}*/}
                  {/*fileList={this.state.fileList}*/}
                  {/*>*/}
                  {/*<p className="ant-upload-drag-icon">*/}
                  {/*<Icon type="upload" />*/}
                  {/*</p>*/}
                  {/*<p className="ant-upload-hint">Fai clic o trascina la foto in quest'area per caricarla</p>*/}
                  {/*</Upload.Dragger>*/}
                  {/*)}*/}
                  {/*</div>*/}
                  {/*</FormItem>*/}

                  <FormItem wrapperCol={{ span: 18, offset: 6 }}>
                    <Checkbox onChange={onChange.bind(this)}>Orientatore</Checkbox>

                    {/*<Button type="primary" htmlType="submit">*/}
                    {/*Pubblica*/}
                    {/*</Button>*/}

                  </FormItem>
                </Form>
              </div>


            </AntCol>

            <AntCol span={12}>

              <h5 style={{ marginTop: -30 }} >Informativa Privacy</h5>
              <br />
              <div
                style={{
                  background: "#ECECEC",
                  padding: "30px",
                  maxHeight: "400px",
                  overflow: "auto",
                  borderColor: "#969292",
                  borderWidth: "5px",
                  borderStyle: "solid"
                }}
              >

                <p>
                  Informazioni privacy sul trattamento dei dati personali ai sensi dell’Art.
                  13 del Regolamento Europeo 2016/679 relativo alla protezione delle persone
                  fisiche con riguardo al trattamento dei dati personali (GDPR)
                </p>

                <h6>Gentile docente,</h6>

                <p>
                  i dati personali (ad esempio nome, cognome, mail personale, e password), da
                  lei comunicati in occasione della registrazione al servizio offerto dal sito
                  dashboard.3ellejesi.it non verranno utilizzati per finalità di marketing ne
                  divulgati a servizi commerciali di terze parti.

                </p>

                <p>
                  I dati in oggetto saranno impiegati esclusivamente per garantire l’operatività del sistema.
                </p>


              </div>
              <br />

              <Checkbox checked={this.state.licensed} onChange={this.acceptLicense}>
                <span style={{ fontSize: 11, color: "#3FA9FF" }}>
                  Selezionando questa opzione dichiaro di aver accettato I’infomativa in oggetto
          </span>
              </Checkbox>



            </AntCol>
          </AntRow>

        </CustomModal>

      </div>
    );
  }
}
const LoginForm = Form.create()(Login);

export default LoginForm;
