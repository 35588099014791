// CoreUI Icons Set
import "@coreui/icons/css/coreui-icons.min.css";
// Import Flag Icons Set
import "flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
import "./App.css";

// Containers
import { DefaultLayout } from "./containers";
// Import Main styles for this application
import "./scss/style.css";
// Pages
import { Login, Page404, Register,ResetPassword,ForgotPassword, DownloadFile } from "./views/Pages";
import PrivateRoute from "./privateRoute";
import routes from "./routes";
class App extends Component {
  pathIsInRoutes(pathname) {
    return routes.filter(route => {
      return route.path === pathname;
    }).length
      ? true
      : false;
  }
  render() {
    return (
      <Router>
        <Route
          render={({ location }) => {
            return (
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/resetPassword"  name="resetPassword"  component={ResetPassword}/>
                <Route exact path="/DownloadFile" component={DownloadFile} />
                <Route
                  exact
                  path="/forgotPassword"
                  name="ForgotPassword"
                  component={ForgotPassword}
                />
                <PrivateRoute
                  exact
                  path={location.pathname}
                  name="Home"
                  component={DefaultLayout}
                  // component={this.pathIsInRoutes(location.pathname) ? DefaultLayout : Page404}
                />
              </Switch>
            );
          }}
        />
      </Router>
    );
  }
}

export default App;
