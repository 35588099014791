import React, { Component } from "react";
import "../../../../node_modules/antd/dist/antd.css";
import "./styles.css";
import {
  Form,
  Button,
  Upload,
  Icon,
  Input,
  message,
  Modal,
  Row,
  Col,
  Table,
  Checkbox,
  Divider,
  Tooltip,
  Select
} from "antd";
import Api from "../../../services/Api";
import ClientSession from "../../../services/ClientSession";
import { Link } from "react-router-dom";
import { Radio, Badge, Popover } from 'antd';
import MaterialDatatable from "material-datatable";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";

const RadioGroup = Radio.Group;

const RadioButton = Radio.Button;
const RadioGroup2 = Radio.Group;

message.config({
  top: '50%',
  duration: 2,
  maxCount: 3,
});
const { Column } = Table;
const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;
const Option = Select.Option;

let plainOptions = [];
let defaultCheckedList = [];
let plainOptionsid = [];

var items = [
  ["Tutti", "cittadinanza", "giustizia", "legalità", "contesti", "internazionali", "economia", "geografia", "politica", "innovazione", "internet", "impresa e Stato", "beni comuni",],
  ["Tutti", "Matematica", "scienze naturali", "biologia", "fisica", "chimica", "meteo", "astronomia",],
  ["Tutti", "biotecnologie", "alimentazione", "chimica organica e scienze agroalimentari", "alimentazione", "benessere", "stili di vita", "estetica e sport",],
  ["Tutti", "psicologia", "sociologia", "statistica", "antropologia", "cura", "relazioni con gli altri", "aspetti sociali",],
  ["Tutti", "arte", "design", "architettura", "multimedialità", "restauro", "colori", "spettacolo", "musica", "comunicazione", "moda", "grafica", "arti decorative",],
  ["Tutti", "cultura", "libri e documenti antichi", "storia e letteratura classica e contemporanea", "filosofia", "societa' contemporanea", "letteratura e lingue straniere moderne", "traduzioni letterarie",],
  ["Tutti", "tecniche realizzative", "modellazione", "materiali", "misure", "impianti", "attrezzature e macchinari", "prototipi", "dall'idea al progetto", "dal progetto alla realizzazione",],
  ["Tutti", "coding e programmazione", "software", "elettronica", "robotica", "domotica", "meccatronica", "progettazione prototipazione e stampa 3D",],
  ["Tutti", "paesaggio", "patrimonio naturale", "agricoltura e valore rurale", "energia", "sicurezza", "ambiente territorio",],
  ["Tutti", "accoglienza", "enogastronomia", "tipicita’", "economia", "turismo e animazione",],
  ["Tutti",]
];

var scope = ["AMBITO-1 ECONOMIA e TERRITORIO", "AMBITO-2 SCIENZA, RICERCA E INNOVAZIONE", "AMBITO-3 SOSTENIBILITÀ E BENESSERE", "AMBITO-4 SOCIETA' CONTEMPORANEA", "AMBITO-5 ARTE, CULTURA E COMUNICAZIONE", "AMBITO-6 DALL'ALFA ALLA ZED", "AMBITO-7 ARTIGIANATO E MESTIERI 4.0", "AMBITO-8 TECNICA E TECNOLOGIA", "AMBITO-9 SOSTENIBILITA' AMBIENTALE", "AMBITO-10 SAPERI E SAPORI", "Tutti"]

class Files extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      filesList: [],
      userId: "",
      eventId: "",
      inputDisabled: false,
      startAndEndDate: [],
      loading: false,
      visible: false,
      events: [],
      checkedList: defaultCheckedList,
      plainOptions: [],
      plainOptionsid: [],
      checkAll: false,
      curid: '',
      value: 10,
      searchText: '',
      fileCategory: 'Tutti',
      class: 'Tutti'

    };
    this.userId = this.props.match.params.studentId;

  }


  getAllEvents = () => {
    ClientSession.getAccessToken((isLoggedIn, authData) => {
      if (!isLoggedIn) return;
      Api.find("events", null)
        .then(response => {
          plainOptions = [];
          for (var i = 0; i < response.data.length; i++) {
            plainOptions.push({ label: response.data[i].title, value: response.data[i].id });
            plainOptionsid.push(response.data[i].id);
          }
          this.setState({ plainOptions: plainOptions });
          this.setState({ plainOptionsid: plainOptions });
        })
        .catch(function (error) {
          console.error("Cannot fetch Events.");
        });
    });
  };

  downloadFile = (file) => {
    Api.find("containers/files/download/" + file, null, null)
      .then(response => {

      })
  }

  onChange = (checkedList) => {
    this.setState({
      checkedList,
      indeterminate: !!checkedList.length && (checkedList.length < plainOptions.length),
      checkAll: checkedList.length === plainOptions.length,
    });
  }

  pick = (obj, keys) => {
    return Object.assign({}, ...keys.map(k => (k in obj ? { [k]: obj[k] } : {})));
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let component = this;
        if (this.state.fileList.length) {
          Api.upload("files", this.state.fileList, null)
            .then(response => {
              const files = response.data.result.files.data;
              if (files.length) {
                var file = files[0];
                values.image = `${Api.API_BASE_URL}containers/${file.container}/download/${
                  file.name
                  }`;
                values.size = file.size;
                values.type = file.type;
                values.atime = new Date().toISOString();
                values.name = file.name;
                this.getAllFiles()

                this.updateDocument(values);
              } else {
                // component.updateEvents(values);
              }
            })
            .catch(error => {

            });
        } else {
          // component.updateEvents(values);
        }
      }
    })

  };

  deletFile = (name, id) => {
    ClientSession.getAccessToken((isLoggedIn, authData) => {
      if (!isLoggedIn) return;
      // const filter = `filter={"where":{"userId" : "${id}"}}`;
      Api.deleteFile("files", name)
        .then(response => {

        })
        .catch(function (error) {
          console.error("Cannot Delete File.");
        });

      Api.destroy("documents", id)
        .then(response => {
          this.setState({ loading: true });

          this.setState({ loading: false, deleteModalVisible: false });
          message.success("Eliminato con successo un file!");
          // this.getAllEvents();
          this.getAllFiles()

        })
        .catch(error => {
          message.error("Si è verificato un errore durante l'eliminazione del file!");
        });


    });

  };

  getAllFiles = () => {
    Api.find("documents", null)
      .then(response => {
        response.data.sort((a, b) => a.class - b.class);
        this.setState({ filesList: response.data });
      })
      .catch(function (error) {
        console.error("Cannot fetch Files.");
      });

  };

  resetFilters = () => {
    this.setState({
      fileCategory: 'Tutti',
      class: 'Tutti',
      value: 10,
    })
    this.getAllFiles()
  }

  getFilterdFiles = () => {
    ClientSession.getAccessToken((isLoggedIn, authData) => {
      if (!isLoggedIn) return;
      let filt = { where: { or: [{ and: [] }] } };

      if (!(this.state.fileCategory === "Tutti")) {
        filt.where.or[0].and.push({ fileCategory: this.state.fileCategory });
      }
      if (!(this.state.class === "Tutti")) {
        filt.where.or[0].and.push({ class: this.state.class });

      }

      let filter = filt.where.or[0].and.length ? "filter=" + JSON.stringify(filt) : "";

      if (filter == "") {
        // filter =  "";
        filter = `filter={"where":{"scope" : "${scope[this.state.value]}"}}`;
        filter = filter == `filter={"where":{"scope" : "Tutti"}}` ? "" : filter;
      }

      // const filter = `filter={"where":{"fileCategory" : "fisica"}}`;
      Api.find("documents", null, filter)
        .then(response => {
          this.setState({ filesList: [] });
          this.setState({ filesList: response.data });
        })
        .catch(function (error) {
          console.error("Cannot fetch Files.");
        });
    });
  };

  updateDocument = files => {
    Api.create("documents", files, null)
      .then(response => {
        message.success("Il file è stato aggiornato con successo! ");
        this.getAllFiles()
      })
      .catch(error => {
        message.error("Oops! Aggiornamento file fallito.");
      });
  };

  // Uploader functions
  beforeUpload = file => {
    this.setState({ fileList: [file] });
    return false;
  };
  onRemove = file => {
    this.setState({ fileList: [] });
  };

  onOk(value) {
    console.log("onOk: ", value);
  }

  componentDidMount() {
    this.getAllFiles()
  }

  //related to modal
  showModal = (id) => {
    this.setState({
      curid: id,
    });
    this.setState({
      visible: true,
    });
    this.getAllEvents();
    Api.find("documents", id)
      .then(response => {
        this.setState({ checkedList: response.data.events });
      })
      .catch(function (error) {
        console.error("Cannot fetch Files.");
      });

  }

  handleOk = () => {
    this.setState({ loading: true });
    let data = { "events": this.state.checkedList }

    Api.update("documents", this.state.curid, data)
      .then(response => {
        message.success("Il file è stato aggiornato con successo! ");
        this.setState({ loading: false });
      })
      .catch(error => {
        message.error("Oops! Aggiornamento file fallito. ");
      });
  }

  handleCancel = () => {
    this.setState({ visible: false });
  }
  ///modal end

  handleSearch = (selectedKeys, confirm) => () => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  }

  handleReset = clearFilters => () => {
    clearFilters();
    this.setState({ searchText: '' });
  }

  handleChange(value) {
    this.setState({
      class: value
    })

  }

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MaterialDatatable: {
          responsiveScroll: {
            maxHeight: window.innerHeight - 270
          }
        },
        MaterialDatatableToolbar: {
          actions: {
            flex: "0 0 65%"
          }
        },
        MuiIconButton: {
          root: {
            color: "#1890FF"
          }
        }
      },
      typography: {
        useNextVariants: true,
        suppressDeprecationWarnings: true
      }
    });

  onAmbitoChange = (value) => {
    this.setState({
      value: value,
    });
  }

  onThemeChange = (value) => {
    this.setState({
      fileCategory: value,
    });
  }

  render() {
    const scopeOptions = scope.map(data => <Option key={data} value={scope.indexOf(data)}>{data}</Option>);
    const themesOptions = items[`${this.state.value}`].map(data => <Option key={data} value={data}>{data}</Option>);

    const columns = [
      {name: "Titolo", field:"fileName"},
      { name: "Ambiti", field: "scope" },
      { name: "Tema", field: "fileCategory" },
      { name: "Unita", field: "class" },
      // { name: "Descrizione", field: "description" },
      {
        name: "Action",
        field: "id",
        options: {
          noHeaderWrap: true,
          width: 200,
          customBodyRender: (value, tableMeta, updateValue) => {
            const content = (
              <div>
                {
                  value.documents ?
                    value.documents.map(data => {
                      let filetype = data.name.split(".").slice(-1)[0]
                      let file = (filetype === "jpeg" || filetype === "png" || filetype === "jpg") ?
                        "file-image" :
                        filetype === "pdf" ? "file-pdf" :
                          filetype === "zip" ? "file-zip" :
                            "file-text"

                      return (<p><a style={{ paddingRight: 10 }} href={data.url} target="_blank"><Icon type={file} theme="filled" style={{ fontSize: '25px' }} />{" " + data.name} </a><br /></p>)
                    }) : ""

                }

              </div>
            );
            return <span>
              {value.documents ?
                <Badge count={value.documents.length} >
                  <Popover content={content} title="Download" trigger="click">
                    <Tooltip title="Scaricare files"> <Icon type="file" style={{ color: "#307BB0", fontSize: 25, paddingRight: 4 }} theme="filled" /> </Tooltip>
                  </Popover>
                </Badge> : ""}


              {/* <a href="javascript: " style={{ paddingLeft: 25, color: "#307BB0" }} onClick={() => { this.showModal(value.id) }}><Icon type="link" className="actions" /> Event</a> */}
            </span>;
          },
          customValue: (value, tableMeta, updateValue) => value.name,
          customSortValue: (value, tableMeta, updateValue) => value.name
        }
      }
    ];

    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    };
    const filesList = this.state.filesList;

    function onFilter(pagination, filters, sorter) {
      this.setState({
        filteredInfo: filters,
      });
    }

    const radioStyle = {
      display: '',
      // width:'100px',
      height: '30px',
      lineHeight: '30px',
      width: '400px',
      alignSelf: 'center',
    };

    const checkStyle = {
      // width:'100px',
      backgroundColor: 'yellow',
      height: '30px',
      lineHeight: '30px',
      // width:'400px',
      alignSelf: 'center',
      display: 'block',
    };

    const options = {
      filterType: "dropdown",
      responsive: "scroll",
      selectableRows: false
    };
    return (
      <div className="align-justify animated fadeIn" style={{ margin: 50 }}>
        <Row gutter={8} style={{ marginBottom: 5 }}>
          <Col span={8}>
            <h6><b>AMBITI</b></h6>
            <Select value={this.state.value} defaultValue="Tutti" onChange={this.onAmbitoChange} style={{ width: "100%" }}>
              {scopeOptions}
            </Select>
          </Col>
          <Col span={6}>
            <h6><b>TEMI</b></h6>
            <Select value={this.state.fileCategory} defaultValue="Tutti" onChange={this.onThemeChange} style={{ width: "100%" }}>
              {themesOptions}
            </Select>

          </Col>
          <Col span={10}>
            <span>
              <h6><b>Action</b></h6>
              {/* 
              <Select
                defaultValue="Tutti"
                style={{ width: "10" }}
                onChange={this.handleChange.bind(this)}>
                <Option value="Tutti" style={{ backgroundColor: "green", color: "white" }} >Tutti</Option>
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3" >3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6" >6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
                <Option value="9">9</Option>
                <Option value="10">10</Option>
              </Select> */}


              <Button onClick={this.getFilterdFiles} icon="search"> Ricerca</Button>
              <Divider type="vertical" />
              <Button onClick={this.resetFilters} icon="reload">Reset</Button>

            </span>

          </Col>
        </Row>
        <br />
        <div className="animated fadeIn align-justify">
          <Row gutter={16}>
            <Col span={24}>

              <MuiThemeProvider theme={this.getMuiTheme()}>
                <MaterialDatatable
                  title={"Documenti"}
                  data={filesList}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>

              {/* <Table bordered columns={columns} dataSource={filesList} />, */}

            </Col>
            <Col span={8}>

            </Col>


          </Row>
        </div>

        <Modal
          visible={this.state.visible}
          title="Seleziona Evento per collegare a un file"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>Annulla</Button>,
            <Button key="submit" type="primary" loading={this.state.loading} onClick={this.handleOk}>
              Presentare
            </Button>,
          ]}
        >
          <div>
            <CheckboxGroup style={{ display: 'grid', 'overflow-y': 'scroll', height: '100px' }} options={this.state.plainOptions} keys={this.state.plainOptionsid} value={this.state.checkedList} onChange={this.onChange} />
          </div>
        </Modal>
      </div>
    );
  }
}

const WrappedDemo = Form.create()(Files);
export default WrappedDemo;


