import Login from './Login';
import Page404 from './Page404';
import Page500 from './Page500';
import Register from './Register';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import DownloadFile from './DownloadFile';
export {
  Login, Page404, Page500, Register,ForgotPassword,ResetPassword,DownloadFile
};
