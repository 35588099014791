import {
  AppAsideToggler,
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler
} from "@coreui/react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Nav } from "reactstrap";
import logo from "../../assets/img/brand/logo.svg";
import sygnet from "../../assets/img/brand/sygnet.svg";
import UserService from "../../services/UserServices";
import { Divider } from "antd";

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onLogoutHandle = () => {
    UserService.logout();
  };

  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: logo, width: 400, height: 50, alt: "3elleorienta" }}
          minimized={{ src: sygnet, width: 30, height: 30, alt: "3elleorienta" }}
        />

        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="ml-auto" navbar>
          <h6> {attributes.user.email} </h6> <Divider type="vertical"/>
          <h6> <a onClick={this.onLogoutHandle}>Logout</a> </h6> <Divider type="vertical"/>
          {/*<AppHeaderDropdown direction="down">*/}
            {/*<DropdownToggle nav>*/}
              {/*<img*/}
                {/*src={"assets/img/avatars/6.jpg"}*/}
                {/*className="img-avatar"*/}
                {/*alt={attributes.user.first_name}*/}
              {/*/>*/}
            {/*</DropdownToggle>*/}
            {/*<DropdownMenu right style={{ right: "auto" }}>*/}
              {/*<DropdownItem header tag="div" className="text-center">*/}
                {/*<strong>Account</strong>*/}
              {/*</DropdownItem>*/}
              {/*<DropdownItem>*/}
                {/*<i className="fa fa-bell-o" /> Updates*/}
                {/*<Badge color="info">42</Badge>*/}
              {/*</DropdownItem>*/}
              {/*<DropdownItem>*/}
                {/*<i className="fa fa-user" /> Profile*/}
              {/*</DropdownItem>*/}
              {/*<DropdownItem>*/}
                {/*<i className="fa fa-wrench" /> Settings*/}
              {/*</DropdownItem>*/}
              {/*<DropdownItem divider />*/}
              {/*<DropdownItem onClick={this.onLogoutHandle}>*/}
                {/*<i className="fa fa-lock" /> Logout*/}
              {/*</DropdownItem>*/}
            {/*</DropdownMenu>*/}
          {/*</AppHeaderDropdown>*/}
        </Nav>
        {/*<AppAsideToggler className="d-md-down-none" />*/}
        {/*<AppAsideToggler className="d-lg-none" mobile />*/}
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
