import React, { Component } from "react";
import { Card, CardBody, CardGroup, Col, Container, Row } from "reactstrap";
import { Form, Icon, Input, Button, message, Checkbox } from "antd";
import { Link } from "react-router-dom";
import "../../../../node_modules/antd/dist/antd.css";
import UserService from "../../../services/UserServices";
import { toUnicode } from "punycode";
const FormItem = Form.Item;
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        UserService.changeTeachersPassword(values.password, this.props.location.search)
          .then(response => {
            if (response.success) {
              // pass the response message to log in  page
              message.success(response.message);
              this.props.history.push('/login');
            } else {
              message.error(response.message);
            }
          })
          .catch(error => {
            message.error(error);
          });
      }
    });
  };

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }
  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="4">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form onSubmit={this.handleSubmit} className="login-form">

                      <FormItem>
                        {getFieldDecorator("password", {
                          rules: [
                            {
                              required: true,
                              message: "Please input your Password!"
                            }
                          ]
                        })(
                          <Input
                            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                            type="password"
                            placeholder="Password"
                          />
                        )}
                      </FormItem>
                      <FormItem>
                        {getFieldDecorator('confirm', {
                          rules: [{
                            required: true, message: 'Please confirm your password!',
                          }, {
                            validator: this.compareToFirstPassword,
                          }],
                        })(
                          <Input
                            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                            type="password" onBlur={this.handleConfirmBlur}
                            placeholder="Confirm Password"
                          />
                        )}
                      </FormItem>
                      <FormItem>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                          Submit
                        </Button>
                      </FormItem>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const ResetPasswordForm = Form.create()(ResetPassword);

export default ResetPasswordForm;
