import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Row
} from "reactstrap";
import logo from '../../../assets/App-Icon.png';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import UserService from "../../../services/UserServices";
//import MobileStoreButton from "react-mobile-store-button";
import { Form,Avatar,message } from "antd";
//import localization from "../../../services/localization";
// import * as conf from "../../../services/config";
import Api from "../../../services/Api";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.state = {
      config: {}
    };
  }

  componentDidMount() {
   // this.getCurrentValues();
    setTimeout(() => {
      this.setState({});
    }, 3000);
  }

  getCurrentValues = () => {
    // const filter = `filter={"where":{"key":"${conf.KEY}"}}`;
    // Api.find(`Configs`, null, filter)
    //   .then(response => {
    //     if (response.data != null && response.data[0] != null) {
    //       this.setState({ config: response.data[0] });
    //     }
    //   })
    //   .catch(error => {
    //     message.error("Oops! Failed to Fetch Data!");
    //   });
  };

  handleValidSubmit(event, values) {
    this.setState({ values });
    const email = this.state.values["email"];
    UserService.reset(email)
      .then(response => {
        if (response.success) {
          // this.props.history.push('/dashboard');
          message.success(response.message);
        } else {
          message.error(response.message);
        }
      })
      .catch(error => {
        message.error("Oops! Something went Wrong Try Again");
      });
  }

  handleInvalidSubmit(event, errors, values) {
    this.setState({ errors, values });
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      {/* <img
                        // src={
                        //   // this.state.config != null && this.state.config.image
                        //   //   ? this.state.config.image
                        //   //   : ""
                        // }
                        style={{ marginBottom: "1rem" }}
                        alt="Vere Italie"
                        className="image_Style"
                      /> */}
                      <Avatar size={60} className="logo" style={{
                      color: '#f56a00', alignSelf: 'center',
                    }} src={logo} />
                    </div>
                    <AvForm
                      onValidSubmit={this.handleValidSubmit}
                      onInvalidSubmit={this.handleInvalidSubmit}
                    >
                      <AvField
                        bsSize="sm"
                        name="email"
                        type="email"
                        placeholder="Email "
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter your email"
                          }
                        }}
                      />
                      <Row>
                        <Col xs="6">
                          <Button
                            bssize="sm"
                            color="primary"
                            className="px-4 bg-info"
                          >
                            Send
                          </Button>
                        </Col>
                        <Col xs="6" className="text-left">
                          <Link color="link" className="px-0" to="/login">
                            Login
                          </Link>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
                <Card
                  className="text-white bg-info pt-5 d-md-down-none"
                  style={{ width: 44 + "%" }}
                >
                  <CardBody className="text-center ">
                    {/* <p style={{ color: "#fff" }}>
                      {this.state.config && this.state.config.name
                        ? localization.Download_Vere_Italie_App.replace(
                            /%.*%/,
                            this.state.config.name
                          )
                        : ""}
                    </p> */}
                    <div
                      className="justify-content-center"
                      style={{ marginLeft: "80px" }}
                    >
                      {/* <MobileStoreButton
                        store="android"
                        url="https://play.google.com/store/apps/details?id=com.med.addis.vere_italie"
                        style={{ width: "120x" }}
                      />
                      <MobileStoreButton
                        store="ios"
                        url="https://itunes.apple.com/it/app/vere-italie/id1422645018?mt=8"
                        style={{ width: "120x" }}
                      /> */}
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const ForgotPass = Form.create()(ForgotPassword);
export default ForgotPass;
