import { Button, Form, Icon, Input, message } from "antd";
import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import "../../../../node_modules/antd/dist/antd.css";
import UserService from "../../../services/UserServices";
import ClientSession from '../../../services/ClientSession';

const FormItem = Form.Item;
class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      roleOptions: [],

    };
  }

  componentDidMount = () => {
    ClientSession.getAuth((err, user) => {
      const roles = user.user.roles.map(val => val.role.name);
      if (roles.includes('Admin')) {
        this.setState({ roleOptions: ['Teacher'] });
      } else if (roles.includes('Teacher')) {
        this.setState({ roleOptions: ['Teacher'] });
      } else {
        // this.props.history.push('/');
      }
    })
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.emailVerified = true;
        values.password = 'passme'; // TODO: THIS HAS TO BE ENCRYPTED yaaa
        values.username = values.email.substring(0, values.email.indexOf('@')) + Math.floor(Math.random() * 10);
        values.role = this.state.roleOptions[0];  // this will allow  Teachers to create  another Teacher
        delete values.confirm_password;
        UserService.register(values)
          .then(response => {
            if (response.success) {
              message.success(response.message);
              //TODO: remove the modal
              this.props.history.push('/');
            } else {
              //TODO: remove the modal
              message.error(response.message);
            }
          })
          .catch(error => {
            console.error("The error is ", error);
          });
      }
    });
  };

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="animated fadeIn">
        <Container>
          <Row>
            <Col md="12">
              <Card className="mx-2">
                <CardBody>
                  <Form onSubmit={this.handleSubmit} className="login-form">
                    <Row>
                      <Col md="6">
                        <FormItem>
                          {getFieldDecorator("first_name", {
                            rules: [{ required: true, message: "Please input your First Name!" }]
                          })(
                            <Input
                              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                              placeholder="First Name"
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col md="6">
                        <FormItem>
                          {getFieldDecorator("last_name", {
                            rules: [{ required: true, message: "Please input your Last Name!" }]
                          })(
                            <Input
                              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                              placeholder="Last Name"
                            />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                    <FormItem>
                      {getFieldDecorator("email", {
                        rules: [
                          {
                            type: "email",
                            message: "The input is not valid E-mail!"
                          },
                          {
                            required: true,
                            message: "Please input your E-mail!"
                          }
                        ]
                      })(
                        <Input
                          prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
                          placeholder="Email"
                        />
                      )}
                    </FormItem>
                    {/* <FormItem>
                      {getFieldDecorator("username", {
                        rules: [{ required: true, message: "Please input your username!" }]
                      })(
                        <Input
                          prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                          placeholder="Username"
                        />
                      )}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator("password", {
                        rules: [
                          { required: true, message: "Please input your Password!" },
                          { validator: this.validateToNextPassword }
                        ]
                      })(
                        <Input
                          prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                          type="password"
                          placeholder="Password"
                        />
                      )}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator("confirm password", {
                        rules: [
                          { required: true, message: "Please confirm your Password!" },
                          { validator: this.compareToFirstPassword }
                        ]
                      })(
                        <Input
                          prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                          type="password"
                          onBlur={this.handleConfirmBlur}
                          placeholder="Confirm password"
                        />
                      )}
                    </FormItem> */}
                    <FormItem>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        onClick={this.props.onRegisterUser}
                      >
                        Register
                      </Button>
                    </FormItem>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const RegisterForm = Form.create()(Register);
export default RegisterForm;
