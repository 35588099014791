import React from "react";
import Loadable from "react-loadable";
import { Button } from "antd";

function Loading() {
  return (
    <span>
      Loading &nbsp;&nbsp;&nbsp;
      <Button type="dashed" size="small" shape="circle" loading />
    </span>
  );
}

const Login = Loadable({
  loader: () => import("./views/Pages/Login"),
  loading: () => Loading
});

const DownloadFile = Loadable({
  loader: () => import("./views/Pages/DownloadFile"),
  loading: () => Loading
});

const Register = Loadable({
  loader: () => import("./views/Pages/Register"),
  loading: () => Loading
});

const About = Loadable({
  loader: () => import("./views/Pages/About"),
  loading: () => Loading
});

const Contact = Loadable({
  loader: () => import("./views/Pages/Contact"),
  loading: () => Loading
});

const Teachers = Loadable({
  loader: () => import("./views/Teachers/Teachers"),
  loading: Loading
});

const Teachers2 = Loadable({
  loader: () => import("./views/Teachers/Teachers2"),
  loading: Loading
});

const TeachersList = Loadable({
  loader: () => import("./views/TeachersList/TeachersList"),
  loading: Loading
});


const StudentsList = Loadable({
  loader: () => import("./views/StudentsList/StudentsList"),
  loading: Loading
});

const Students = Loadable({
  loader: () => import("./views/Students/Students"),
  loading: Loading
});

const StudentsAdmin = Loadable({
  loader: () => import("./views/Students/StudentsAdmin"),
  loading: Loading
});

const News = Loadable({
  loader: () => import("./views/News/News"),
  loading: Loading
});
const Page404 = Loadable({
  loader: () => import("./views/Pages/Page404"),
  loading: Loading
});

const Events = Loadable({
  loader: () => import("./views/Events/Events"),
  loading: Loading
});
const Dashboard = Loadable({
  loader: () => import("./views/Dashboard"),
  loading: Loading
});
const Files = Loadable({
  loader: () => import("./views/Files/Files"),
  loading: Loading
});

const Files2 = Loadable({
  loader: () => import("./views/Files/Files2"),
  loading: Loading
});

const AddEvent = Loadable({
  loader: () => import("./views/AddEvent/AddEvent"),
  loading: Loading
});

const AddNews = Loadable({
  loader: () => import("./views/AddNews/AddNews"),
  loading: Loading
});

const AddStudent = Loadable({
  loader: () => import("./views/Registration/AddStudent"),
  loading: Loading
});

const AddTeacher = Loadable({
  loader: () => import("./views/Registration/AddTeacher"),
  loading: Loading
});

const PreviewNews = Loadable({
  loader: () => import("./views/PreviewNews/PreviewNews"),
  loading: Loading
});

const PreviewEvent = Loadable({
  loader: () => import("./views/PreviewEvent/PreviewEvent"),
  loading: Loading
});

const EditNews = Loadable({
  loader: () => import("./views/News/EditNews/EditNews"),
  loading: Loading
});

const EditFile = Loadable({
  loader: () => import("./views/Files/UploadFile/EditFile/EditFile"),
  loading: Loading
});

const UploadFiles = Loadable({
  loader: () => import("./views/Files/UploadFile/UploadFile"),
  loading: Loading
});

const EditEvent = Loadable({
  loader: () => import("./views/Events/EditEvent/EditEvent"),
  loading: Loading
});

const EditTeacher = Loadable({
  loader: () => import("./views/Teachers/EditTeacher/EditTeacher"),
  loading: Loading
});

const EditStudent = Loadable({
  loader: () => import("./views/Students/EditStudent/EditStudent"),
  loading: Loading
});

const School = Loadable({
  loader: () => import("./views/School/School"),
  loading: Loading
});

const EditSchool = Loadable({
  loader: () => import("./views/School/EditSchool/EditSchool"),
  loading: Loading
});
const configuration = Loadable({
  loader: () => import("./views/Configuration/configuration"),
  loading: Loading
});

const routes = [
  // { exact: true, path: "/", name: "Casa", component: Dashboard },
  { exact: true, path: "/", name: "Casa", component: Events },
  { exact: true, path: "/Login", name: "Accesso", component: Login },
  { exact: true, path: "/DownloadFile", name: "Registrare", component: DownloadFile },
  { exact: true, path: "/about", name: "Chi siamo", component: About },
  { exact: true, path: "/contact", name: "Contatto", component: Contact },
  { exact: true, path: "/register", name: "Registrare", component: Register },
  { exact: true, path: "/teachers", name: "Insegnanti", component: Teachers, role: "Admin" },
  { exact: true, path: "/teachers2", name: "Insegnanti2", component: Teachers2, role: "Admin" },
  { exact: true, path: "/teachersList", name: "Lista degli insegnanti", component: TeachersList, role: "Admin" },
  { exact: true, path: "/studentsList", name: "Elenco degli studenti", component: StudentsList, role: "Admin" },
  { exact: true, path: "/404", name: "404", component: Page404 },
  { exact: true, path: "/news", name: "Notizia", component: News },
  { exact: true, path: "/news/view/:newsId", name: "Anteprima", component: PreviewNews },
  { exact: true, path: "/news/add", name: "Inserisci", component: AddNews },
  { exact: true, path: "/registrationStudent", name: "Registrati Studente", component: AddStudent },
  { exact: true, path: "/registrationTeacher", name: "Registrati Insegnante", component: AddTeacher },
  { exact: true, path: "/news/edit/:newsId", name: "Modificare", component: EditNews },
  { exact: true, path: "/Files/upload/:fileId/edit", name: "Modificare", component: EditFile },
  { exact: true, path: "/Files/upload", name: "Caricare", component: UploadFiles },
  { exact: true, path: "/Files", name: "File", component: Files },
  { exact: true, path: "/Files2", name: "File", component: Files2 },
  { exact: true, path: "/events", name: "Eventi", component: Events },
  { exact: true, path: "/events/view/:eventId", name: "Anteprima", component: PreviewEvent },
  { exact: true, path: "/events/add", name: "Inserisci", component: AddEvent },
  { exact: true, path: "/events/:eventId/edit", name: "Modificare", component: EditEvent },
  { exact: true, path: "/Teachers/:teacherId/edit", name: "Modificare", component: EditTeacher },
  { exact: true, path: "/Students/:studentId/edit", name: "Modificare", component: EditStudent },
  { exact: true, path: "/students", name: "Studenti", component: Students },
  { exact: true, path: "/studentsAdmin", name: "Studenti Admin View", component: StudentsAdmin },
  { exact: true, path: "/school", name: "Scuole", component: School },
  { exact: true, path: "/school/:schoolId/edit", name: "Modifica", component: EditSchool },
  { exact: true, path: "/school/create", name: "Creare", component: EditSchool },
  { exact: true, path: "/configuration", name: "Configurazione", component: configuration }

];

export default routes;
