import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav
} from "@coreui/react";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
// routes config
import routes from "../../routes";
// sidebar nav config
import navigation from "../../_nav";
// import DefaultAside from './DefaultAside';
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import ClientSession from "../../../src/services/ClientSession"
import Api from '../../../src/services/Api';
class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: null,
      userId: "",
      user: {}
    };
    this.getCurrentUserRole();
  }


  getCurrentUserRole() {
    ClientSession.getAuth((err, value) => {
      const id = value.user.id;
      if (value.user.roles.length === 1)
        Api
          .find('roles', value.user.roles[0].roleId, null)
          .then((response) => {
            this.setState({ role: response.data.name });
          })
          .catch((error) => {
            console.error(error);
          })
      Api.find('users', id, null)
        .then((response) => {
          this.setState({
            userId: id,
            user: response.data
          });
        });

    })

  }

  render() {
    const menuName = this.state.role ? this.state.role : null;
    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader user={this.state.user} />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={navigation[menuName]} {...this.props} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes} />
            <Container fluid>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component && (route.role === this.state.role || !route.role) ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => <route.component {...props} />}
                    />
                  ) : null;
                })}
              </Switch>
            </Container>
          </main>
          <AppAside fixed hidden>
            {/* <DefaultAside /> */}
          </AppAside>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

export default DefaultLayout;
