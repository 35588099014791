const schoolOwnerNav = {
  items: [
    {
      title: true,
      name: "Scuola",
      wrapper: {
        element: "", // required valid HTML5 element tag
        attributes: {} // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "" // optional class names space delimited list for title item ex: "text-center"
    },
    {
      divider: true
    },
    {
      name: "Scuola",
      url: "/school",
      icon: "fa fa-home"
    },
    {
      title: true,
      name: "Avvenimento",
      wrapper: {
        // optional wrapper object
        element: "", // required valid HTML5 element tag
        attributes: {} // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "" // optional class names space delimited list for title item ex: "text-center"
    },
    {
      divider: true
    },
    {
      name: "Eventi",
      url: "/events",
      icon: "fa fa-calendar"
    },
    // {
    //   name: "News",
    //   url: "/news",
    //   icon: "fa fa-newspaper-o"
    // },
    {
      title: true,
      name: "Utenti",
      wrapper: {
        element: "",
        attributes: {}
      },
      class: ""
    },
    {
      divider: true
    },
    {
      name: "Orientatore",
      url: "/teachers",
      icon: "fa fa-pencil-square-o",
    },
    {
      name: "Insegnanti",
      url: "/teachers2",
      icon: "fa fa-pencil-square-o",
    },
    {
      name: "Lista Degli Insegnanti",
      url: "/teachersList",
      icon: "fa fa-users"
    },
    {
      name: "Elenco Degli Studenti",
      url: "/studentsList",
      icon: "fa fa-users"
    },
    // {
    //   name: "Studenti",
    //   url: "/students",
    //   icon: "fa fa-graduation-cap"
    // },
    {
      name: "Studenti",
      url: "/studentsAdmin",
      icon: "fa fa-graduation-cap"
    },
    {
      title: true,
      name: "File",
      wrapper: {
        element: "",
        attributes: {}
      },
      class: ""
    },
    {
      divider: true
    },
    {
      name: "Carica Documento",
      url: "/files/upload",
      icon: "fa fa-upload"
    },
    {
      name: "Trova Documento",
      url: "/files",
      icon: "fa fa-search"
    },
    {
      title: true,
      name: "Registrazione",
      wrapper: {
        element: "",
        attributes: {}
      },
      class: ""
    },
    {
      divider: true
    },
    {
      name: "Registra Insegnante",
      url: "/registrationTeacher",
      icon: "fa fa fa-user-plus",
    }, {
      name: "Configuration",
      url: "/configuration",
      icon: "fa fa-gear"
    },

    // {
    //   title: true,
    //   name: "Pagine",
    //   wrapper: {
    //     element: "",
    //     attributes: {}
    //   },
    //   class: ""
    // },
    // {
    //   divider: true
    // },
    // {
    //   name: "Chi Siamo",
    //   url: "/about",
    //   icon: "fa fa fa-file-text",
    // },
    // {
    //   name: "Contattaci",
    //   url: "/contact",
    //   icon: "fa fa fa-file-text",
    // }

  ]
};

const teacherNav = {
  items: [
    {
      title: true,
      name: "Avvenimento",
      wrapper: {
        // optional wrapper object
        element: "", // required valid HTML5 element tag
        attributes: {} // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "" // optional class names space delimited list for title item ex: "text-center"
    },
    {
      divider: true
    },
    {
      name: "Eventi",
      url: "/events",
      icon: "fa fa-calendar"
    },
    // {
    //   name: "News",
    //   url: "/news",
    //   icon: "fa fa-newspaper-o"
    // },
    {
      title: true,
      name: "Utenti",
      wrapper: {
        element: "",
        attributes: {}
      },
      class: ""
    },
    {
      divider: true
    },
    {
      name: "Studenti",
      url: "/students",
      icon: "fa fa-graduation-cap"
    },
    // {
    //   name: "Studenti",
    //   url: "/studentsAdmin",
    //   icon: "fa fa-graduation-cap"
    // },
    {
      title: true,
      name: "File",
      wrapper: {
        element: "",
        attributes: {}
      },
      class: ""
    },
    {
      divider: true
    },
    {
      name: "Carica Documento",
      url: "/files/upload",
      icon: "fa fa-upload"
    },
    {
      name: "Trova Documento",
      url: "/files",
      icon: "fa fa-search"
    }
  ]
}

const studentNav = {
  items: [
    {
      title: true,
      name: "Avvenimento",
      wrapper: {
        // optional wrapper object
        element: "", // required valid HTML5 element tag
        attributes: {} // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "" // optional class names space delimited list for title item ex: "text-center"
    },
    {
      divider: true
    },
    // {
    //   name: "Eventi",
    //   url: "/events",
    //   icon: "fa fa-calendar"
    // },
    // {
    //   name: "News",
    //   url: "/news",
    //   icon: "fa fa-newspaper-o"
    // },
    // {
    //   title: true,
    //   name: "Utenti",
    //   wrapper: {
    //     element: "",
    //     attributes: {}
    //   },
    //   class: ""
    // },
    // {
    //   divider: true
    // },
    // {
    //   name: "Studenti",
    //   url: "/students",
    //   icon: "fa fa-graduation-cap"
    // },
    // {
    //   title: true,
    //   name: "File",
    //   wrapper: {
    //     element: "",
    //     attributes: {}
    //   },
    //   class: ""
    // },
    // {
    //   divider: true
    // },
    // {
    //   name: "Carica Documento",
    //   url: "/files/upload",
    //   icon: "fa fa-upload"
    // },
    {
      name: "Trova Documento",
      url: "/files2",
      icon: "fa fa-search"
    }
  ]
}




const navigation = { "Admin": schoolOwnerNav, "Teacher": teacherNav, "Student": studentNav };
export default navigation;